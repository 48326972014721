import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../layouts/layout'

class WhatIsNLP extends React.Component {
  render () {
    return (
      <Layout>
        {/* Page Header */}
        <header className="masthead what_is_nlp">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="page-heading">
                  <h1>What is NLP?</h1>
                  <span className="subheading">From our friends at <a href="https://en.wikipedia.org/wiki/Natural_language_processing" style={{color:'gray'}}>Wikipedia</a>: Natural language processing (NLP) is a field of computer science, artificial intelligence and computational linguistics concerned with the interactions between computers and human (natural) languages, and, in particular, concerned with programming computers to fruitfully process large natural language corpora.</span>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Main Content */}
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <p>In layman's terms, it's a way for computers to almost act human and understand text as a human would, but just in a very fast and automated manner.</p>
              <p>Because <i>text</i> and <i>langauge</i> are used literally everywhere around the globe, (no pun intended), NLP has applications to virtually everything around the world. From freelance artists to the finance industry, from hospitals and the health care system to sports and athletics, NLP has applications everywhere and is growing in popularity.</p>
              <p>Still want to know more? Check out our <Link to ="/getting_started">getting started</Link> page!</p>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default WhatIsNLP;
